var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, o) {
  exports = o();
}(exports, function () {
  var o = !1;

  function t(t) {
    (this || _global).opts = function () {
      for (var t = 1; t < arguments.length; t++) for (var o in arguments[t]) arguments[t].hasOwnProperty(o) && (arguments[0][o] = arguments[t][o]);

      return arguments[0];
    }({}, {
      onClose: null,
      onOpen: null,
      beforeOpen: null,
      beforeClose: null,
      stickyFooter: !1,
      footer: !1,
      cssClass: [],
      closeLabel: "Close",
      closeMethods: ["overlay", "button", "escape"]
    }, t), this.init();
  }

  function e() {
    (this || _global).modalBoxFooter && ((this || _global).modalBoxFooter.style.width = (this || _global).modalBox.clientWidth + "px", (this || _global).modalBoxFooter.style.left = (this || _global).modalBox.offsetLeft + "px");
  }

  return t.prototype.init = function () {
    if (!(this || _global).modal) return function () {
      (this || _global).modal = document.createElement("div"), (this || _global).modal.classList.add("tingle-modal"), 0 !== (this || _global).opts.closeMethods.length && -1 !== (this || _global).opts.closeMethods.indexOf("overlay") || (this || _global).modal.classList.add("tingle-modal--noOverlayClose");
      (this || _global).modal.style.display = "none", (this || _global).opts.cssClass.forEach(function (t) {
        "string" == typeof t && (this || _global).modal.classList.add(t);
      }, this || _global), -1 !== (this || _global).opts.closeMethods.indexOf("button") && ((this || _global).modalCloseBtn = document.createElement("button"), (this || _global).modalCloseBtn.type = "button", (this || _global).modalCloseBtn.classList.add("tingle-modal__close"), (this || _global).modalCloseBtnIcon = document.createElement("span"), (this || _global).modalCloseBtnIcon.classList.add("tingle-modal__closeIcon"), (this || _global).modalCloseBtnIcon.innerHTML = "<svg viewBox=\"0 0 10 10\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M.3 9.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3L5 6.4l3.3 3.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L6.4 5l3.3-3.3c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L5 3.6 1.7.3C1.3-.1.7-.1.3.3c-.4.4-.4 1 0 1.4L3.6 5 .3 8.3c-.4.4-.4 1 0 1.4z\" fill=\"#000\" fill-rule=\"nonzero\"/></svg>", (this || _global).modalCloseBtnLabel = document.createElement("span"), (this || _global).modalCloseBtnLabel.classList.add("tingle-modal__closeLabel"), (this || _global).modalCloseBtnLabel.innerHTML = (this || _global).opts.closeLabel, (this || _global).modalCloseBtn.appendChild((this || _global).modalCloseBtnIcon), (this || _global).modalCloseBtn.appendChild((this || _global).modalCloseBtnLabel));
      (this || _global).modalBox = document.createElement("div"), (this || _global).modalBox.classList.add("tingle-modal-box"), (this || _global).modalBoxContent = document.createElement("div"), (this || _global).modalBoxContent.classList.add("tingle-modal-box__content"), (this || _global).modalBox.appendChild((this || _global).modalBoxContent), -1 !== (this || _global).opts.closeMethods.indexOf("button") && (this || _global).modal.appendChild((this || _global).modalCloseBtn);

      (this || _global).modal.appendChild((this || _global).modalBox);
    }.call(this || _global), function () {
      (this || _global)._events = {
        clickCloseBtn: (this || _global).close.bind(this || _global),
        clickOverlay: function (t) {
          var o = (this || _global).modal.offsetWidth - (this || _global).modal.clientWidth,
              e = t.clientX >= (this || _global).modal.offsetWidth - 15,
              s = (this || _global).modal.scrollHeight !== (this || _global).modal.offsetHeight;
          if ("MacIntel" === navigator.platform && 0 == o && e && s) return;
          -1 !== (this || _global).opts.closeMethods.indexOf("overlay") && !function (t, o) {
            for (; (t = t.parentElement) && !t.classList.contains(o););

            return t;
          }(t.target, "tingle-modal") && t.clientX < (this || _global).modal.clientWidth && this.close();
        }.bind(this || _global),
        resize: (this || _global).checkOverflow.bind(this || _global),
        keyboardNav: function (t) {
          -1 !== (this || _global).opts.closeMethods.indexOf("escape") && 27 === t.which && this.isOpen() && this.close();
        }.bind(this || _global)
      }, -1 !== (this || _global).opts.closeMethods.indexOf("button") && (this || _global).modalCloseBtn.addEventListener("click", (this || _global)._events.clickCloseBtn);
      (this || _global).modal.addEventListener("mousedown", (this || _global)._events.clickOverlay), window.addEventListener("resize", (this || _global)._events.resize), document.addEventListener("keydown", (this || _global)._events.keyboardNav);
    }.call(this || _global), document.body.appendChild((this || _global).modal, document.body.firstChild), (this || _global).opts.footer && this.addFooter(), this || _global;
  }, t.prototype._busy = function (t) {
    o = t;
  }, t.prototype._isBusy = function () {
    return o;
  }, t.prototype.destroy = function () {
    null !== (this || _global).modal && (this.isOpen() && this.close(!0), function () {
      -1 !== (this || _global).opts.closeMethods.indexOf("button") && (this || _global).modalCloseBtn.removeEventListener("click", (this || _global)._events.clickCloseBtn);
      (this || _global).modal.removeEventListener("mousedown", (this || _global)._events.clickOverlay), window.removeEventListener("resize", (this || _global)._events.resize), document.removeEventListener("keydown", (this || _global)._events.keyboardNav);
    }.call(this || _global), (this || _global).modal.parentNode.removeChild((this || _global).modal), (this || _global).modal = null);
  }, t.prototype.isOpen = function () {
    return !!(this || _global).modal.classList.contains("tingle-modal--visible");
  }, t.prototype.open = function () {
    if (!this._isBusy()) {
      this._busy(!0);

      var t = this || _global;
      return "function" == typeof t.opts.beforeOpen && t.opts.beforeOpen(), (this || _global).modal.style.removeProperty ? (this || _global).modal.style.removeProperty("display") : (this || _global).modal.style.removeAttribute("display"), document.getSelection().removeAllRanges(), (this || _global)._scrollPosition = window.pageYOffset, document.body.classList.add("tingle-enabled"), document.body.style.top = -(this || _global)._scrollPosition + "px", this.setStickyFooter((this || _global).opts.stickyFooter), (this || _global).modal.classList.add("tingle-modal--visible"), "function" == typeof t.opts.onOpen && t.opts.onOpen.call(t), t._busy(!1), this.checkOverflow(), this || _global;
    }
  }, t.prototype.close = function (t) {
    if (!this._isBusy()) {
      if (this._busy(!0), !1, "function" == typeof (this || _global).opts.beforeClose) if (!(this || _global).opts.beforeClose.call(this || _global)) return void this._busy(!1);
      document.body.classList.remove("tingle-enabled"), document.body.style.top = null, window.scrollTo({
        top: (this || _global)._scrollPosition,
        behavior: "instant"
      }), (this || _global).modal.classList.remove("tingle-modal--visible");
      var o = this || _global;
      o.modal.style.display = "none", "function" == typeof o.opts.onClose && o.opts.onClose.call(this || _global), o._busy(!1);
    }
  }, t.prototype.setContent = function (t) {
    return "string" == typeof t ? (this || _global).modalBoxContent.innerHTML = t : ((this || _global).modalBoxContent.innerHTML = "", (this || _global).modalBoxContent.appendChild(t)), this.isOpen() && this.checkOverflow(), this || _global;
  }, t.prototype.getContent = function () {
    return (this || _global).modalBoxContent;
  }, t.prototype.addFooter = function () {
    return function () {
      (this || _global).modalBoxFooter = document.createElement("div"), (this || _global).modalBoxFooter.classList.add("tingle-modal-box__footer"), (this || _global).modalBox.appendChild((this || _global).modalBoxFooter);
    }.call(this || _global), this || _global;
  }, t.prototype.setFooterContent = function (t) {
    return (this || _global).modalBoxFooter.innerHTML = t, this || _global;
  }, t.prototype.getFooterContent = function () {
    return (this || _global).modalBoxFooter;
  }, t.prototype.setStickyFooter = function (t) {
    return this.isOverflow() || (t = !1), t ? (this || _global).modalBox.contains((this || _global).modalBoxFooter) && ((this || _global).modalBox.removeChild((this || _global).modalBoxFooter), (this || _global).modal.appendChild((this || _global).modalBoxFooter), (this || _global).modalBoxFooter.classList.add("tingle-modal-box__footer--sticky"), e.call(this || _global), (this || _global).modalBoxContent.style["padding-bottom"] = (this || _global).modalBoxFooter.clientHeight + 20 + "px") : (this || _global).modalBoxFooter && ((this || _global).modalBox.contains((this || _global).modalBoxFooter) || ((this || _global).modal.removeChild((this || _global).modalBoxFooter), (this || _global).modalBox.appendChild((this || _global).modalBoxFooter), (this || _global).modalBoxFooter.style.width = "auto", (this || _global).modalBoxFooter.style.left = "", (this || _global).modalBoxContent.style["padding-bottom"] = "", (this || _global).modalBoxFooter.classList.remove("tingle-modal-box__footer--sticky"))), this || _global;
  }, t.prototype.addFooterBtn = function (t, o, e) {
    var s = document.createElement("button");
    return s.innerHTML = t, s.addEventListener("click", e), "string" == typeof o && o.length && o.split(" ").forEach(function (t) {
      s.classList.add(t);
    }), (this || _global).modalBoxFooter.appendChild(s), s;
  }, t.prototype.resize = function () {
    console.warn("Resize is deprecated and will be removed in version 1.0");
  }, t.prototype.isOverflow = function () {
    return window.innerHeight <= (this || _global).modalBox.clientHeight;
  }, t.prototype.checkOverflow = function () {
    (this || _global).modal.classList.contains("tingle-modal--visible") && (this.isOverflow() ? (this || _global).modal.classList.add("tingle-modal--overflow") : (this || _global).modal.classList.remove("tingle-modal--overflow"), !this.isOverflow() && (this || _global).opts.stickyFooter ? this.setStickyFooter(!1) : this.isOverflow() && (this || _global).opts.stickyFooter && (e.call(this || _global), this.setStickyFooter(!0)));
  }, {
    modal: t
  };
});
export default exports;